import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(normal_layout)": [~7,[2]],
		"/(normal_layout)/admin": [~8,[2]],
		"/(no_layout)/auth/logged-in": [3],
		"/(no_layout)/auth/login": [4],
		"/(no_layout)/auth/logout": [~5],
		"/(normal_layout)/changelog": [9,[2]],
		"/(normal_layout)/discover": [10,[2]],
		"/(normal_layout)/discover/[city]": [~11,[2]],
		"/(normal_layout)/e/new": [~14,[2]],
		"/(normal_layout)/e/[slug]": [~12,[2]],
		"/(normal_layout)/e/[slug]/edit": [~13,[2]],
		"/(no_layout)/legal/privacy-policy": [6],
		"/(normal_layout)/profile/[slug]": [~15,[2]],
		"/(normal_layout)/user/events": [~16,[2]],
		"/(normal_layout)/user/profile": [~17,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';